import React, { PureComponent } from 'react';

/*
 * Shuffle function
 * source: https://www.frankmitchell.org/2015/01/fisher-yates/
 * 
 **/

function shuffle(array) {
  let i = 0;
  let j = 0;
  let temp = null;

  for (i = array.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1));
    temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
}

class TypeWord extends PureComponent {
  constructor(props) {
    super();

    this.delayTimeout = null;

    this.state = {
      currentWordComplete: false,
      currentLetter: 0,
      currentWord: 0,
      words: shuffle(props.words)
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.delayTimeout);
  }

  typeWords() {
    const { rotateWordSpeed, typeWordSpeed } = this.props;
    const { currentWordComplete, currentLetter, currentWord, words } = this.state;
    const wordCount = words.length - 1;

    if (wordCount > 0) {
      const currentWordLength = words[currentWord].length;

      if (!currentWordComplete) {

        if (currentLetter < currentWordLength) {

          this.delayTimeout = setTimeout(() => {

            this.setState({
              currentLetter: currentLetter + 1
            });

          }, typeWordSpeed);

        } else {
          
          this.delayTimeout = setTimeout(() => {

            this.setState({
              currentWordComplete: true
            });
            
          }, rotateWordSpeed);
          
        }

      }

      if (currentWordComplete) {
        
        if (currentLetter !== 0) {

          this.delayTimeout = setTimeout(() => {
            this.setState({
              currentLetter: currentLetter - 1
            });
          }, typeWordSpeed / 2);

        } else {

          if (currentWord < wordCount) {

            this.delayTimeout = setTimeout(() => {

              this.setState({
                currentLetter: 0,
                currentWord: currentWord + 1,
                currentWordComplete: false
              });
            }, typeWordSpeed * 3);

          } else {

            this.delayTimeout = setTimeout(() => {

              this.setState({
                currentLetter: 0,
                currentWord: 0,
                currentWordComplete: false,
                words: shuffle(words)
              });

            }, typeWordSpeed * 3);

          }

        }

      }

      return words[currentWord].substr(0, currentLetter);
    }

  }

  render() {
    return (
      <span
        className="type-word">
        {this.typeWords()}
      </span>
    );
  }
}

export default TypeWord;
