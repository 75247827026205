import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import { Converter } from 'showdown';

import Layout from '../components/layout';
import TypeWord from '../components/type-word';
import InstaLogo from '../images/instagram-logo.svg';

import { setPageHeading } from '../state/app/app-actions';

import './index.scss';

const baseClass = 'about-main';

class IndexPage extends PureComponent {
    constructor({ data }) {
        super();
        this.state = {
            pageData: data.contentfulPage
        };
    }

    componentDidMount() {
        this.props.updatePageHeading(this.state.pageData.heading);
    }

    maybeRenderLifeRolesHeading() {
        const { subHeading, pageMetadata: { life_roles } } = this.state.pageData;
        const LIFE_ROLES = '@LIFE_ROLES@';
        // const splitHeading = subHeading.split(LIFE_ROLES);

        return (
            <span>
                {subHeading.split(' ').map((split, i) => {

                    if (split === LIFE_ROLES) {
                        return <TypeWord
                            key={`split-type-word`}
                            rotateWordSpeed={2500}
                            typeWordSpeed={72}
                            words={life_roles}
                        />
                    }

                    return (
                        <span
                            key={`split-${i}`}
                            dangerouslySetInnerHTML={{ __html: split + ' ' }}
                        />
                    );
                })}
            </span>
        );
    }

    render() {
        const { pageData } = this.state;
        const { mainContent: { mainContent: mainBody } } = pageData;
        const converter = new Converter();
        let content = converter.makeHtml(mainBody);

        return (
            <Layout>
                <div className={baseClass}>
                    <h1 className={`${baseClass}__heading`}>A little bit about me</h1>
                    <h3 className={`${baseClass}__sub-heading`}>{this.maybeRenderLifeRolesHeading()}</h3>
                    <div
                        className={`${baseClass}__content`}
                        dangerouslySetInnerHTML={{
                            __html: content
                        }}
                    />
                    <ul className="social__list">
                        <li>
                            <a href="http://instagram.com/willdavidow">
                                <img src={InstaLogo} alt="Instagram: Will Davidow" className="insta-logo" />
                            </a>
                        </li>
                    </ul>
                </div>
            </Layout>
        );
    }
}

export const query = graphql`
  query IndexPage {
    contentfulPage(title: {eq: "Home"}) {
      heading
      subHeading
      mainContent {
        mainContent
      }
      pageMetadata {
        life_roles
      }
    }
  }
`;

const mapStateToProps = (state) => {
    const {
        pageHeading
    } = state.app;

    return {
        pageHeading
    };
}

const mapDispatchToProps = (dispatch) => {
    const updatePageHeading = pageHeading => dispatch(setPageHeading(pageHeading));

    return {
        updatePageHeading
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IndexPage);
